import React from "react"
import loadable from '@loadable/component'

// import EditorGutenbergWpBlockGallery from './../../../../../components/s4n/Editor/Gutenberg/WpBlockGallery'



export default (node) => {
    const nid = typeof node.drupal_internal__nid !== `undefined` ? node.drupal_internal__nid : null;

    const ProductRelated = loadable(() => import(`./../../../../../components/s4n/Shop/Product/related`));
    const Subpages = loadable(() => import(`./../../../../../components/s4n/Menu/Subpages`));
    const Portfolio = loadable(() => import(`./../../../../../components/s4n/Portfolio`));
    const GoogleMap = loadable(() => import(`./../../../../../components/s4n/GoogleMap`));
  
    let oReplacement = {
      [`[s4n_related_products]`]: <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />,
      [`[s4n_subpages]`]: <Subpages nid={nid} />,
      [`[s4n_subpages_service]`]: <Subpages nid={14} template={`service`} />,
      [`[s4n_portfolio]`]: <Portfolio header={true} limit={6} />,
      // [`dynamic-component-path=<Team propName="propValue" />`]: true,
      [`[s4n_service_googlemaps]`]: <GoogleMap />,
    }
  
    if (14 === nid) { // Service
      oReplacement[`[s4n_subpages]`] = <Subpages nid={nid} template={`service`} disableHeader={true} />
    }
    if (17 === nid) { // Portfolio
      oReplacement[`[s4n_portfolio]`] = <Portfolio />
    }

    return oReplacement;
}