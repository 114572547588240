import React from "react"

// s4n
import { injectIntl, Link } from "gatsby-plugin-intl"
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';

import PromotedFrontPage from './../Promoted/FrontPage'



const ComponentStateless = (props) => {
    const { intl, pageContext, linkTranslation } = props;
    const t = intl.formatMessage;

    if (!pageContext.allTags.length) {
        return null;
    }

//   let ButtonClass = classNames({
//     "btn mr-1": true,
//     // 'btn-primary': tag === pageContext.tag,
//     // 'btn-outline-primary': tag !== pageContext.tag,
//   });

    let ButtonClass = classNames({
        // "btn mr-1 mb-1": true,
        // 'btn-primary': typeof pageContext.tag === `undefined`,
        // 'btn-outline-primary': typeof pageContext.tag !== `undefined`,
    });

    return (
        <React.Fragment>
			
            <div className={`item media-text bg-grey blog-quick-menu`}>
                <div className={`row`}>
                    <div className={props.columnClass}>
                        <div className={`inner`}>
                            <div className={`title`}>
                                { t({ id: "soft4net_lists_division_tags" }) }
                            </div>
                            <div className={`content`}>
                                <Link to={`/${linkTranslation}`} className={ButtonClass} role="button">{ t({ id: "soft4net_lists_division_tags_all" }) }</Link>
                                {pageContext.allTags.map(tag => {
                                    ButtonClass = classNames({
                                        // "btn mr-1 mb-1": true,
                                        // 'btn-primary': tag === pageContext.tag,
                                        // 'btn-outline-primary': tag !== pageContext.tag,
                                    });

                                    return <Link to={`/${linkTranslation}/tag/${kebabCase(tag)}`} className={ButtonClass} role="button">{tag}</Link>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={props.columnClass}>
                        <div className={`inner`}>
                            <div className={`title`}>
                                <Link to={``} title={``}>
                                    {/* { t({ id: "soft4net_lists_division_tags" }) } */}
                                    Ostatnie wpisy
                                </Link>
                            </div>
                            <div className={`content`}>
                                {/* <PromotedFrontPage limit={6} disableImages={true} disableSummary={true} disableButtonMore={true} /> */}
                                <PromotedFrontPage limit={6} disableWrapp={true} disableHeader={true} returnOnlyLink={true} anchorClass={`nav-link`} />
                                {/* <PromotedFrontPage limit={6} disableWrapp={true} CustomWrapper={props => <li className="dropdown-item">{props.children}</li>} returnOnlyLink={true} anchorClass={`nav-link`} /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {false &&
                <div className="container tags">
                    <div className="row">
                        <div className="col">
                            <strong>{ t({ id: "soft4net_lists_division_tags" }) }</strong>

                            {/* hide on screens wider than lg, @see: https://getbootstrap.com/docs/4.0/utilities/display/ */}
                            <div className="d-lg-none">
                                <div className="dropdown">
                                    {typeof pageContext.tag === `undefined` && 
                                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            { t({ id: "soft4net_lists_division_tags_all" }) }
                                        </button>
                                    }

                                    {typeof pageContext.tag !== `undefined` && 
                                        pageContext.allTags
                                        .filter(tag => tag === pageContext.tag)
                                        .map(tag => {
                                            return (
                                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    { tag }
                                                </button>
                                            )
                                        })
                                    }

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {typeof pageContext.tag !== `undefined` && 
                                            <Link to={`/${linkTranslation}`} className="dropdown-item">{ t({ id: "soft4net_lists_division_tags_all" }) }</Link>}

                                        {pageContext.allTags
                                            .filter(tag => tag !== pageContext.tag)
                                            .map(tag => {
                                                return <Link to={`/${linkTranslation}/tag/${kebabCase(tag)}`} className="dropdown-item">{tag}</Link>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* hide on screens smaller than lg, @see: https://getbootstrap.com/docs/4.0/utilities/display/ */}
                            <div className="d-none d-lg-block">
                                <div>
                                    <Link to={`/${linkTranslation}`} className={ButtonClass} role="button">{ t({ id: "soft4net_lists_division_tags_all" }) }</Link>
                                    {pageContext.allTags.map(tag => {
                                        ButtonClass = classNames({
                                            "btn mr-1 mb-1": true,
                                            'btn-primary': tag === pageContext.tag,
                                            'btn-outline-primary': tag !== pageContext.tag,
                                        });

                                        return <Link to={`/${linkTranslation}/tag/${kebabCase(tag)}`} className={ButtonClass} role="button">{tag}</Link>
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }

        </React.Fragment>
    )
}

export default injectIntl(ComponentStateless)