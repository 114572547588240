import React from "react"
import Layout from "./../../layout"
import SEO from "./../../seo"

// s4n
// import "./../assets/sass/pages/_blog.scss"
import { useIntl } from "gatsby-plugin-intl"
import Tags from "./tags"
import BlogIndexTemplate from "./../templates/blog-index"
import Pagination from './../Pagination'
import kebabCase from "lodash.kebabcase"

import Page from './../../../model/node/page'
import { useParseInlineImages } from './../../../hooks/drupal/parseInlineImages'
import useReplacementNode from './../../../hooks/drupal/parseContentProcessor/useReplacement/Node'


const ComponentStateless = (props) => {
	const intl = useIntl();
	const t = intl.formatMessage;

	const { propsData } = props;
	const { pageContext, aDataCollectionTranslated } = propsData;

	const linkMap = {
		pl: {
			blog: `Blog`,
			news: `Aktualności`,
		},
		en: {
			blog: `Blog`,
			news: `News`,
		},
		de: {
			blog: `Blog`,
			news: `News`,
		}
	}

	const nodeInfoForLayoutComponent = {
		title: linkMap[intl.locale][pageContext.field_type],
		internal: {
			type: ``,
		},
		drupal_internal__nid: process.env.SOFT4NET_BLOG_PAGE_DRUPAL_ID,
		drupal_internal__product_id: null,
	}

	const linkTranslation = kebabCase(linkMap[intl.locale][pageContext.field_type]);



	// Get node data for Blog page
	const nodeBlog = Page.getItemByNodeId(process.env.SOFT4NET_BLOG_PAGE_DRUPAL_ID, intl.locale);
    const oReplacement = useReplacementNode(nodeBlog);
    const nodeBodyField = useParseInlineImages(nodeBlog, true, oReplacement);

	const tags = (
		<div key={`blog-item-${`tags`}`} className={`col-lg-6`}>
			<Tags pageContext={pageContext} linkTranslation={linkTranslation} columnClass={`col-12 col-xl-6`} />
		</div>
	)

	return (
		<Layout
			node={nodeInfoForLayoutComponent}
			className="s4n-blog-index"
		>
			<SEO title={linkMap[intl.locale][pageContext.field_type]} keywords={``} nodeId={process.env.SOFT4NET_BLOG_PAGE_DRUPAL_ID} />

			<div className="container">
				<div className="row">
					<div className="col">
						<h1 className="mt-5 mb-3">{linkMap[intl.locale][pageContext.field_type]}</h1>
					</div>
				</div>
			</div>

			{nodeBodyField}

			{/* <Tags pageContext={pageContext} linkTranslation={linkTranslation} /> */}
			<BlogIndexTemplate data={propsData} intl={intl} translate={intl.formatMessage} tags={tags} />
			<Pagination pageContext={pageContext} linkTranslation={linkTranslation} />
		</Layout>
	)
}

export default ComponentStateless